export default defineI18nConfig(() => ({
  fallbackLocale: {
    sk: ['cs', 'en'],
    default: ['en', 'cs'],
  },
  pluralRules: { // inspiration: https://github.com/scottrippey/Smart-Plurals/tree/master/src/Smart.Plurals
    cs: (value, choicesLength) => {
      const singular = (value === 1);
      if (choicesLength === 2) { return singular ? 0 : 1 }

      const few = (value >= 2 && value <= 4);
      return singular ? 0 : few ? 1 : 2;
    },

    fr: (value) => {
      const singular = (value === 0 || value === 1);
      return (singular ? 0 : 1);
    },

    ru: (value, choicesLength) => {
      const singular = (value % 10 === 1 && value % 100 !== 11);
      if (choicesLength === 2) { return singular ? 0 : 1 }

      const few = (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20));
      return singular ? 0 : few ? 1 : 2;
    },

    uk: (value, choicesLength) => {
      const singular = (value % 10 === 1 && value % 100 !== 11);
      if (choicesLength === 2) { return singular ? 0 : 1 }

      const few = (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20));
      return singular ? 0 : few ? 1 : 2;
    },

    pl: (value, choicesLength) => {
      const singular = (value === 1);
      if (choicesLength === 2) { return singular ? 0 : 1 }

      const few = (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20));
      return singular ? 0 : few ? 1 : 2;
    },

  },
}));
